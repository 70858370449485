import React from "react"
import Manager from "../components/manager";
import Theme from "../components/theme";

export default function LoginPage() {
  return (<Manager name="login">
    <Theme>
      {"404 - File Not Found"}
    </Theme>
  </Manager>);
}


